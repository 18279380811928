<template>
  <div class="chartone">
    <div class="chart">
      <div v-for="item in equipmentData" :key="item.name">
        <ja-init-chart ref="chart" class="jachart flipping" v-if="item.options.series" :options="item.options" />
        <p><span>{{item.name}}</span><span>{{item.value}}</span></p>
        <p><span>离线</span><span>{{item.fangOffOnline}}</span></p>
        <p><span>在线</span><span>{{item.fangOnline}}</span></p>
      </div>
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  name: 'water',
  components: {
    JaInitChart
  },
  props:{
    equipmentData:{
      type:Array,
      default:()=>[]
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
  
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.$refs.chart.myChart.resize()
    },
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .header {
  left: 0 !important;
  top: 20px !important;
}
.chartone {
  width: 100%;
  height: 100%;
  position: relative;
  .header {
    position: absolute;
    left: 5px;
    top: 15px;
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .icons {
      width: 12px;
      height: 8px;
    }
    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .rotating {
      animation: rotate 5s linear infinite;
      /* 5s表示一次旋转的时间，linear表示线性动画，infinite表示无限循环 */
    }

    .flipping {
      animation: flip 5s linear infinite;
      /* 5s表示一次翻转的时间，linear表示线性动画，infinite表示无限循环 */
    }

    @keyframes flip {
      0% {
        transform: perspective(400px) rotateY(0deg);
      }

      50% {
        transform: perspective(400px) rotateY(180deg);
      }

      100% {
        transform: perspective(400px) rotateY(360deg);
      }
    }

  .chart {
    height: 100%;
    width: 100%;
    padding: 0 20px;
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    & div {
      width: auto;
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      p{
        margin: 10px auto;
        display: flex;
        justify-content: space-between;
      }
      & span:nth-of-type(1){
        margin-right: 6px;
      }

    }
    .jachart {
      margin: 0 auto;
      margin-bottom: 10px;
      width: 100px;
      height: 100px;
      background-image: url('~@/assets/gd/chartbg1.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      // background-color: rgba(11, 56, 102, 1);
    }
  }
}
</style>