<template>
  <div class="bg box_bg">

    <div class="charts">
      <ja-init-chart class="jachart" :options="options" />
      <div class="chartimg flipping"></div>
      <ul>
        <li v-for="(item, index) in alarmList" :key="index">
          <span>{{ item.value }}</span>
        </li>
        <div v-if="alarmList.length == 0">
          截至当前暂无设备告警信息！
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
export default {
  props: {
    alarmList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    JaInitChart
  },
  data() {
    return {
      // alarmList: []
      options: {}
    }
  },
  created() {
    this.init()
  },
  watch: {
    alarmList() {
      this.init()
    }
  },
  methods: {
    async init() {
      this.loading = false
      this.options = {
        legend: {
          type: 'plain',
          orient: 'vertical',
          top: '11%',
          left: '58%',
          icon: 'diamond',
          itemGap: 12,
          itemWidth: 7, // 设置宽度
          itemHeight: 7, // 设置高度
          textStyle: {
            color: '#DAFFE8',
            fontSize: this.ChartSize(14)
          }
        },
        color: ['#7DFF89', '#397AFF', '#FFEED5', '#FFBE8F', '#9776FF'],
        series: [
          // {
          //   // 内圆刻度
          //   type: 'gauge',
          //   startAngle: '90',
          //   endAngle: '-269.9999',
          //   radius: '61%',
          //   splitNumber: 20,
          //   center: ['27%', '45%'],
          //   z: 4,
          //   axisLine: {
          //     show: false
          //   },
          //   pointer: {
          //     show: false
          //   },
          //   axisTick: {
          //     show: false
          //   },
          //   splitLine: {
          //     length: 10,
          //     lineStyle: {
          //       color: '#002f64 ',
          //       width: 2
          //     }
          //   },
          //   axisLabel: {
          //     show: false
          //   },
          //   axisLine: {
          //     show: false,
          //     lineStyle: {
          //       color: [
          //         [0.1, '#7DFF89'],
          //         [0.3, '#397AFF'],
          //         [0.5, '#FFEED5'],
          //         [0.8, '#FFBE8F'],
          //         [1, '#9776FF']
          //       ],
          //       width: 10
          //     }
          //   },
          //   detail: {
          //     show: false
          //   }
          // },
          {
            name: '',
            type: 'pie',
            startAngle: 100,
            radius: ['46%', '55%'],
            avoidLabelOverlap: false,
            hoverOffset: 0,
            top: '-10%',
            left: '-46%',
            label: {
              show: false,
              position: 'left'
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: this.alarmList
          },
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  .charts {
    margin-top: 50px;
  }

  ul {
    right: 65px !important;

    li {
      width: 220px !important;
    }
  }
}

.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .header {
    height: 36px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_dialogtitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .icons {
      width: 12px;
      height: 8px;
    }

    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 50px;
    height: 100%;
    position: relative;
    top: -5px;
    display: flex;

    .chartimg {
      z-index: 1;
      position: absolute;
      left: 34px;
      top: 18px;
      width: 126px;
      height: 126px;
      background-image: url('~@/assets/gd/chartbg2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .rotating {
      animation: rotate 5s linear infinite;
      /* 5s表示一次旋转的时间，linear表示线性动画，infinite表示无限循环 */
    }

    .flipping {
      animation: flip 5s linear infinite;
      /* 5s表示一次翻转的时间，linear表示线性动画，infinite表示无限循环 */
    }

    @keyframes flip {
      0% {
        transform: perspective(400px) rotateY(0deg);
      }

      50% {
        transform: perspective(400px) rotateY(180deg);
      }

      100% {
        transform: perspective(400px) rotateY(360deg);
      }
    }

    .jachart {
      z-index: 2;
      width: 360px;
      height: 180px;
    }

    ul {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 14px;
      width: 170px;
      padding-top: 21px;
      padding-right: 140px;
      position: absolute;
      right: 265px;

      >div {
        font-size: 14px;
        text-align: center;
        margin-top: 25px;
        width: 100%;
        color: #eee;
        width: 220px;
        padding: 20px 0;
      }

      li {
        font-size: 12.6px;
        color: rgba(255, 255, 255, 0.7);
        width: 220px;
        height: 21px;
        line-height: 21px;
        background-image: url('~@/assets/gd/ds_chatli.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 5px;
        text-align: right;
        padding-bottom: 12.5px;
        padding-right: 10px;
        align-items: center;

        span {
          color: #ffffff;
        }
      }
    }
  }
}</style>