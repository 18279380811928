<template>
    <div class="entry bg" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="boxdiv">
            <equipment-statistics class="flow"></equipment-statistics>
        </div>
        <div class="boxdiv">
            <equipment-statistics-two class="flow"></equipment-statistics-two>
        </div>
        <div class="boxdiv">
            <equipment-statistics-three class="flow"></equipment-statistics-three>
        </div>
        <div class="boxdiv">
            <waterChart class="flow" :waterData="waterData" />
        </div>
        <div class="boxdiv">
            <waterChartTwo class="flow" :waterData="waterData" />
        </div>
        <div class="boxdiv">
            <waterChartThree class="flow" :waterData="waterData" />
        </div>
        <div class="boxdiv">
            <alarmChart :alarmList="alarmList" />
        </div>
        <div class="boxdiv">
            <alarmChartTwo :alarmList="alarmList" />
        </div>
        <div class="boxdiv">
            <alarmChartThree />
        </div>
        <div class="boxdiv">
            <sectorChart :equipmentData="equipmentData" />
        </div>

        <div class="boxdiv">
            <sectorChartTwo :equipmentData="equipmentData2" />
        </div>

        <div class="boxdiv">
            <sectorChartThree :equipmentData="equipmentData3" />
        </div>






    </div>
</template>

<script>
import equipmentStatistics from './equipmentStatistics/index.vue'
import equipmentStatisticsTwo from './equipmentStatisticsTwo/index.vue'
import equipmentStatisticsThree from './equipmentStatisticsThree/index.vue'
import waterChart from './waterChart/index.vue'
import waterChartTwo from './waterChartTwo/index.vue'
import waterChartThree from './waterChartThree/index.vue'
import alarmChart from './alarmChart/index.vue'
import alarmChartTwo from './alarmChartTwo/index.vue'
import alarmChartThree from './alarmChartThree/index.vue'
import sectorChart from './sectorChart/sectorChart.vue'
import sectorChartTwo from './sectorChartTwo/sectorChart.vue'
import sectorChartThree from './sectorChartThree/sectorChart.vue'
export default {
    title: '德舜大厦可视化管理平台',
    name: 'officeBuilding',
    components: {
        equipmentStatistics,
        equipmentStatisticsTwo,
        equipmentStatisticsThree,
        waterChart,
        waterChartTwo,
        waterChartThree,
        alarmChart,
        alarmChartTwo,
        alarmChartThree,
        sectorChart,
        sectorChartTwo,
        sectorChartThree
    },
    data() {
        return {
            waterData: {
                "totalMonitor": 532,
                "onlineMonitor": 355,
                "offlineMonitor": 166
            },
            alarmList: [
                { name: '非法闯入', value: 61 },
                { name: '设备异常', value: 233 },
                { name: '非法入侵', value: 113 },
                { name: '设备入侵', value: 45 },
                { name: '非法进入', value: 85 },
            ],
            equipmentData: [
                {
                    "name": "门禁设备",
                    "value": 581,
                    "fangOnline": 381,
                    "fangOffOnline": 200,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    381,
                                    200
                                ]
                            }
                        ]
                    }
                },
                {
                    "name": "道闸设备",
                    "value": 41,
                    "fangOnline": 20,
                    "fangOffOnline": 21,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    21,
                                    20,
                                ]
                            }
                        ]
                    }
                },
                {
                    "name": "智能梯控",
                    "value": 18,
                    "fangOnline": 7,
                    "fangOffOnline": 11,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    11,
                                    7
                                ]
                            }
                        ]
                    }
                }
            ],
            equipmentData2: [
                {
                    "name": "门禁设备",
                    "value": 581,
                    "fangOnline": 381,
                    "fangOffOnline": 200,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    381,
                                    200
                                ]
                            }
                        ]
                    }
                },
                {
                    "name": "道闸设备",
                    "value": 41,
                    "fangOnline": 20,
                    "fangOffOnline": 21,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    21,
                                    20,
                                ]
                            }
                        ]
                    }
                },
                {
                    "name": "智能梯控",
                    "value": 18,
                    "fangOnline": 7,
                    "fangOffOnline": 11,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    11,
                                    7
                                ]
                            }
                        ]
                    }
                }
            ],
            equipmentData3: [
                {
                    "name": "门禁设备",
                    "value": 581,
                    "fangOnline": 381,
                    "fangOffOnline": 200,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    381,
                                    200
                                ]
                            }
                        ]
                    }
                },
                {
                    "name": "道闸设备",
                    "value": 41,
                    "fangOnline": 20,
                    "fangOffOnline": 21,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    21,
                                    20,
                                ]
                            }
                        ]
                    }
                },
                {
                    "name": "智能梯控",
                    "value": 18,
                    "fangOnline": 7,
                    "fangOffOnline": 11,
                    "options": {
                        "color": [
                            "#FFDD30",
                            "#54FFD2"
                        ],
                        "series": [
                            {
                                "type": "gauge",
                                "startAngle": "0",
                                "endAngle": "360",
                                "radius": "40%",
                                "splitNumber": 30,
                                "center": [
                                    "50%",
                                    "50%"
                                ],
                                "z": 4,
                                "axisLine": {
                                    "show": false
                                },
                                "pointer": {
                                    "show": false
                                },
                                "axisTick": {
                                    "show": false
                                },
                                "splitLine": {
                                    "length": 2,
                                    "lineStyle": {
                                        "color": "#002f64 ",
                                        "width": 1
                                    }
                                },
                                "axisLabel": {
                                    "show": false
                                },
                                "detail": {
                                    "show": false
                                }
                            },
                            {
                                "name": "",
                                "type": "pie",
                                "startAngle": "0",
                                "radius": [
                                    "30%",
                                    "65%"
                                ],
                                "avoidLabelOverlap": false,
                                "hoverOffset": 0,
                                "label": {
                                    "show": false,
                                    "position": "left"
                                },
                                "emphasis": {
                                    "label": {
                                        "show": false
                                    }
                                },
                                "labelLine": {
                                    "show": false
                                },
                                "data": [
                                    11,
                                    7
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    created() {
        this.startDataInterval()
    },
    mounted() {
        this.$emit('loading')
    }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.bg {
    height: 100%;
    position: relative;
    overflow: hidden;

    .scene {
        width: 100%;
        height: 100%;
        transform: scale(1);
    }
}

.entry {
    width: 100%;
    background-image: url('~@/assets/gdbgimg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .boxdiv {
        height: 25%;
        width: 33%;
    }

    .flow {
        height: 100%;
        width: 100%;
        flex: 1;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}
</style>
