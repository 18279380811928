<template>
  <div class="bg box_bg">
          <div class="box">
            <div class="statistical">
              <div class="deviceTotal"></div>
              <div class="Toalmsg">
                <div>
                  <p>在线</p>
                  <p class="date">{{ info.onlineQuantity }}</p>
                </div>
                <div>
                  <p>离线</p>
                  <p class="date">{{ info.offlineQuantity }}</p>
                </div>
                <div>
                  <p>异常</p>
                  <p class="date">{{ info.numberOfAnomalies }}</p>
                </div>
              </div>
            </div>
            <div class="charts" @mouseenter="stopAnimation" @mouseleave="startAnimation">
              <ja-init-chart
                class="jachart"
                ref="chart"
                :options="options"
                v-if="!noDataText"
              />
              <div class="chartimg"></div>
              <ul :class="deviceInfoList.length <=5 ? 'ultop':''">
                <li v-for="item in 5" :key="item">
                </li>
              </ul>
            </div>
          </div>
        
  </div>
</template>

<script>
import { deviceStatistics, deviceInfoPost } from '@/api'
import WPanelEntry from '../../IOC/WPanelEntry'
import JaInitChart from '@/components/ja-init-chart/index'
import NoData from '@/components/noData'
export default {
  title: '设备统计',
  name: 'equipmentStatistics',
  components: {
    JaInitChart,
    NoData,
    WPanelEntry,
  },
  data() {
    return {
      options: {},
      loading: true,
      info: {},//设备统计数据
      deviceInfoList: [],
      deviceTotal:'',//设备总数量
      Visiable: false,
      title: '设备统计',
      deviceInfoPost,
      conditionData: [
        {
          input_type: 'date',
          type: 'daterange',
          key: 'time',
          value: 'time',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          valueFormat: 'yyyy-MM-dd'
        },
        { normalBtn: 'search', limit: 'default:view' },
        { normalBtn: 'reset' }
      ],
      columns: [
        {
          label: '设备名称',
          prop: 'deviceName'
        },
        { label: '设备位置', prop: 'installLocation'},
        { label: '状态', prop: 'runStatus'}
      ],
      systemurl: '/home/deviceManage/deviceList',
      noDataText:'',
      dataInterval:null,
      currentInnerRadiusIndex: 0, // 当前内半径索引
      innerRadiusValues: ['65%', '60%', '55%', '50%','55%','60%','65%','70%'], // 内半径的取值列表
      innerRadiusValuestwo: ['85%', '80%', '75%', '70%', '75%', '80%', '85%', '90%'], // 内半径的取值列表
    }
  },
  mounted() {
    // if (this.$store.state.token) {
    this.noDataText = ''
    this.init()
    this.dataInterval = setInterval(() => {
      const newInnerRadius = this.innerRadiusValues[this.currentInnerRadiusIndex];
      const newInnerRadiustwo = this.innerRadiusValuestwo[this.currentInnerRadiusIndex];
      // 更新饼图半径
      this.options.series[0].radius = [newInnerRadius, newInnerRadiustwo];
      // this.$refs.chart.refreshChart();  // 刷新图表
      this.currentInnerRadiusIndex = (this.currentInnerRadiusIndex + 1) % 8;
    }, 300); // 每隔30毫秒更新一次
    // } else {
    //   this.noDataText = '-- 请求数据缺少token --'
    //   this.loading = false
    // }
  },
  methods: {
    stopAnimation() {
      clearInterval(this.dataInterval);
    },
    startAnimation() {
        this.dataInterval = setInterval(() => {
        const newInnerRadius = this.innerRadiusValues[this.currentInnerRadiusIndex];
        const newInnerRadiustwo = this.innerRadiusValuestwo[this.currentInnerRadiusIndex];
        // 更新饼图半径
        this.options.series[0].radius = [newInnerRadius, newInnerRadiustwo];
        // this.$refs.chart.refreshChart();  // 刷新图表
        this.currentInnerRadiusIndex = (this.currentInnerRadiusIndex + 1) % 8;
      }, 300); // 每隔30毫秒更新一次
    },
    generateRandomInnerRadius() {
      // 生成随机的饼图内半径参数
      return `${Math.floor(Math.random() * 50) + 20}%`;
    },
    async init() {
      //获取设备统计数据
      const {data} = await deviceStatistics()
      data.equipmentStatistics.map(item=>{
        item.name = item.deviceName || ''
        item.value = item.deviceTotal || ''
        delete item.deviceName
        delete item.deviceTotal
      })
      this.deviceInfoList = data.equipmentStatistics
      this.info = data
      this.loading = false
      this.options = {
        title: {
          text: this.info.totalNumberOfDevices,
          subtext: '设备总数',
          top: '30%',
          left: '13%',
          textStyle: {
            color: '#DAFFE8',
            fontSize: this.ChartSize(22),
            fontWeight: '900',
            textShadow: '0px 0px 16px rgba(18,253,240,0.5)'
          },
          subtextStyle: {
            color: '#DAFFE880',
            fontSize: this.ChartSize(13),
            fontWeight: 'normal',
            fontWeight: '900'
          }
        },
        legend:{
          top: '10%',
          type:'scroll',
          left: '50%',
          top: "center",
          orient: 'vertical',
          itemGap:this.ChartSize(12),
          // right: 'right',
          itemWidth:  this.ChartSize(5),
          itemHeight:  this.ChartSize(17),
          pageIconSize: this.ChartSize(13),
          pageTextStyle: {
            color: '#fff',
            fontSize: this.ChartSize(14)
          },
          formatter: e => {
            //通过e的内容,获取后台的百分比和车数
            let value = 0;
            let proportion = 0;
            this.deviceInfoList.map(item => {
              if (item.name === e) {
                value = item.value
                proportion = item.proportion
              }
            })
            const str = `${e}  ${value}/${proportion}%`
            return str
          },
          textStyle: {
            color: '#DAFFE8',
            fontSize: this.ChartSize(14)
          }
        },
        color: ['#12FDF0', '#F47153', '#02B1FF', '#FFCE14', '#09C889'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['70%', '90%'],
            avoidLabelOverlap: false,
            left: '-60%',
            startAngle: 100,
            label: {
              show: false,
              position: 'left'
            },
            // itemStyle: {
            //   borderColor: '#fff',
            //   borderWidth: 3
            // },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            data: this.deviceInfoList
          },
        
        ],
      }
    },
    openDialog() {
      this.$refs.dialog.showDialog()
    },
    
  },
  watch: {
    '$store.state.token': function () {
      if (this.$store.state.token) {
        this.noDataText = ''
        this.init()
      } else {
        this.noDataText = '-- 请求数据缺少token --'
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';
.box {
  display: flex;
  flex-direction: column;
  color: #fff;
  width: 95%;
  height: 85%;
  margin: 0 auto;
  justify-content: space-between;
  span {
    text-align: right;
  }
  span:nth-child(1) {
    color: #fbd437;
    cursor: pointer;
  }
  img {
    width: 7px;
    height: 12px;
    margin-left: 6px;
  }
  .statistical {
    margin-top: 10px;
    margin-left: 10px;
    background-image: url('~@/assets/gd/ds_chatmsg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 400px;
    height: 60px;
    display: flex;
    .deviceTotal {
      width: 30%;
      text-align: center;
      font-size: 14px;
    }
    .Toalmsg {
      width: 70%;
      box-sizing: border-box;
      padding: 5px 0;
      display: flex;
      justify-content: space-around;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        p {
          text-align: center;
          font-size: 12px;
          color: #daeeff;
          // padding-top: 40px;
        }
        .date {
          font-size: 20px;
          color: #b4b4b4;
        }
      }
      > div:first-child {
        .date {
          color: #02ffac;
        }
      }
      > div:last-child {
        .date {
          color: #ffce14;
        }
      }
    }
  }
  .charts {
    background-image: url('~@/assets/gd/ds_chartbgbing.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 10px;
    width: 420px;
    position: relative;
    top: -5px;
    display: flex;
    .chartimg{
      z-index: 1;
      position: absolute;
      left: 8px;
      top: 6px;
      width: 150px;
      height: 150px;
      background-image: url('~@/assets/gd/ds_chartbing.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .jachart {
      z-index: 2;
      width: 420px;
      height: 160px;
    }
    .ultop{
      top: 15px;
    }
    ul {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 14px;
      width: 160px;
      padding-top: 3px;
      right: 2px;
      position: absolute;
      right: 50px;

      li {
        font-size: 12.6px;
        color: rgba(255, 255, 255, 0.7);
        width: 160px;
        height: 22px;
        line-height: 21px;
        background-image: url('~@/assets/gd/ds_chatli.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 5px;
        text-align: right;
        padding-bottom: 12.5px;
        padding-right: 10px;
        align-items: center;
        span {
          color: #ffffff;
        }
      }
    }
  }
}
// }
</style>

